<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
    <div class="card">
      <div class="card-header">Profile</div>
      <div class="card-body">
        <v-form ref="form" lazy-validation v-model="valid">
          <div class="row" v-if="edit_profile">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Name<span class="red--text"><strong>* </strong></span></label>
              <v-text-field type="text" v-model="student.name" :rules="[v => (!!v && !/[^a-zA-Z ]/g.test(v)) || 'Enter valid name']" outlined ></v-text-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Email<span class="red--text"><strong>* </strong></span></label>
              <v-text-field type="text" v-model="student.user.email" outlined readonly></v-text-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Country<span class="red--text"><strong>* </strong></span></label>
                <v-autocomplete v-model="edit_profile.country" :items="countries" item-text="text" item-value="value" :rules="[v => !!v || 'Select country']" v-on:click="getRegions()" searchable outlined></v-autocomplete>
            </div>                  
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Region</label>
                <v-autocomplete v-model="edit_profile.region" :items="regions" item-text="text" item-value="value" searchable outlined></v-autocomplete>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="row">
                <div class="col-lg-3 pt-0">
                  <label for="inputText3" class="col-form-label">Code</label>
                  <v-autocomplete v-model="edit_profile.code" :items="codes" item-text="text" item-value="value"  @input="getCountry" outlined></v-autocomplete>
                </div>
                <div class="col-lg-9 pt-0">
                  <label for="inputText3" class="col-form-label">Phone</label>
                  <v-text-field type="text" v-model="student.user.mobile" outlined readonly></v-text-field>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pb-0">
              <label for="inputText3" class="col-form-label">Course</label>
                <v-autocomplete  v-model="student.course_id" :items="courses" item-text="text" item-value="value" outlined></v-autocomplete>
            </div>   
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <a href="" v-on:click.prevent="updateStudent()" class="btn btn-success btn-block text-white w-100">Save Changes</a>
            </div>
          </div>
        </v-form>
      </div>
    </div>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p>Do you really want to Update these student details?</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-danger" v-if="loading">...</button>
            <button type="button" class="btn btn-danger" v-if="!loading" v-on:click="updateRecord">Save</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal">Cancel</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import AWS from 'aws-sdk';
export default {
  name:'Student-Profile',
  props:["student"],
  data(){
    return {
      edit_profile:{
        name:null,
        mobile:null,
        country_code:null,
        profile_pic:null,
        country_id:null,
        region_id:null,
        course_id:null,
      },
      countries:[],
      codes:[],
      courses:[],
      regions:[],
      valid:false,
      reset_valid:false,
      loading:false,
      profile_pic:null,
      mobileRules:[
        v => !!v || 'Enter valid mobile',
        v => /\D/g.test(v) == false || 'Enter 10 digit mobile number',
        v => (v && v.length == 10) || 'Enter 10 digit mobile number'
      ],
      passwordRules:[
        v => !!v || 'Enter valid password',
        // v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
      ],
      passwordConfirmationRules:[
        v => !!v || 'Enter valid password',
        v => this.reset_password.new_password == v || 'Password not match'
      ],
    }
  },
  beforeMount(){
    this.getCountries();
    this.getCourses();
  },
  methods:{
    hideModal(){
      this.$refs['confirm-modal'].hide();
    },
    setProfile(){
      this.edit_profile.name = (this.student.name)?this.student.name:null;
      this.edit_profile.email = (this.student.email)?this.student.email:null;
      this.edit_profile.mobile = (this.student.mobile)?this.student.mobile:null;
      this.edit_profile.phone = (this.student.phone)?this.student.phone:null;
      this.edit_profile.country_id = (this.student.country_id)?this.student.country_id:null;
      this.edit_profile.course_id = (this.student.course_id)?this.student.course_id:null;
      this.edit_profile.region_id = (this.student.region_id)?this.student.region_id:null;
      this.edit_profile.profile_pic = (this.student.profile_pic)?this.student.profile_pic:null;
      this.edit_profile.country_code = (this.student.user.code)?parseInt(this.student.user.code):null;
      this.setCountry();
      this.listRegions(this.student.country_id)
    },
    getCountries(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/countries',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.countries.forEach(country => {
          this.countries.push({value:country.id,text:country.name,code:country.phonecode,short:country.sortname});
          this.codes.push({value:country.phonecode, text:country.sortname+ '-' + country.phonecode});
        })
      })
    },
    getRegions(){
      this.countries.forEach(country => {
        if(country.value == this.edit_profile.country_id){
          this.edit_profile.country_code = country.code;
        }
      });
      this.listRegions(this.edit_profile.country_id);
    },
    listRegions(id){
      this.regions = [];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/country/'+id,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.states.forEach(region => {
          this.regions.push({value:region.id,text:region.name});
        })
      })
    },
    getCourses(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/courses',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Courses.forEach(course => {
          this.courses.push({value:course.course_id, text:course.course_name});
        })
      })
    },
    setCountry(){
      this.countries.forEach(country => {
        if(country.code == this.edit_profile.country_code){
          this.edit_profile.country_id = country.value;
        }
      });
      this.listRegions(this.edit_profile.country_id);
    },
    getCountry(){
      this.countries.forEach(country => {
        if(country.code == this.edit_profile.code){
          this.profile.country = {value:country.value, text:country.text};
        }
      })
      this.edit_profile.country_id = this.edit_profile.country.value;
      this.listRegions(this.edit_profile.country.value);
    },
    toEditProfile(e){
      e.preventDefault();
      this.loading = false;
      this.valid = this.$refs.form.validate();
      if(this.valid){
        this.$refs['confirm-modal'].show();
      }
    },
    updateRecord(e){
      e.preventDefault();
      this.loading = true;
      if(this.profile_pic != null){
        var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
        var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
        let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
      
        AWS.config.update({
          accessKeyId: keys.accessKeyId,
          secretAccessKey: keys.secretAccessKey,
          region: 'ap-south-1'
        });

        let s3BucketName = "tution-site"; 
        var file = new Blob([this.profile_pic], { type: "application/image" });
        var extension = this.profile_pic.name.split('.')[this.profile_pic.name.split('.').length-1]
      
      var d = new Date();
      var month = d.getMonth() + 1;
      var day = d.getDate();
      var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
      var timestamp = Math.floor(Date.now() / 1000);

      if (file.size < 20000000) {
        var s3obj = new AWS.S3({
          params: {
            Bucket: s3BucketName,
            Key:
              'student/profile_pic/'+ current_date+"/"+ timestamp + "."+extension,
            ACL: "public-read",
          },
        });
        var upload = s3obj.upload({ Body: file });
        upload.on("httpUploadProgress", function (evt) {
            // uploadStatus == 0;
            //checkConnetionOffnOn(upload);
            var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
            console.log(per_uploaded);
          })
          .send((err, data) => {
            this.edit_profile.profile_pic = data.Location;
            this.profile_pic = null;
            this.confirmEdit();
          })
      }
      }else{
        this.confirmEdit();
      }
    },
    confirmEdit(){
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/student/'+this.student.student_id,this.edit_profile,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-student').token)}}).then(res => {
        if(res.data.status == 1){
          this.$refs['confirm-modal'].hide();
          var cookie_data = this.$cookies.get('tution-student');
          cookie_data.name = this.edit_profile.name;
          this.$cookies.set("tution-student", cookie_data);
          location.reload();
        }
      })
    }
  }
}
</script>